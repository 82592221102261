import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
// import logo from '../images/fluks.png'

export const NavBar = () => {
  // State to handle navbar collapse in mobile view
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  return (
    <header>
      {/* Main navigation bar */}
      <nav className="relative flex flex-wrap items-center justify-between bg-gray-600 px-4 py-4 text-white shadow-lg">
        <div className="container mx-auto flex flex-wrap items-center justify-between">

          {/* Logo section - clicking on logo redirects to homepage */}
          <div className="flex w-full justify-between md:w-auto">
            <Link
              to="/"
              className="flex items-center text-3xl font-bold uppercase">
              {/* Logo image, important for branding. Add alt text for better SEO */}
              {/* <img src={logo} alt="Arbin Shop Logo" className="h-16 w-16" /> */}
              ARBIN - 06
            </Link>

            {/* Mobile view hamburger icon button */}
            <button
              className="block md:hidden focus:outline-none"
              type="button"
              aria-label="Toggle navigation"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <svg
                className="h-8 w-8 text-white"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"></path>
              </svg>
            </button>
          </div>

          {/* Main navigation links, collapse into burger menu on mobile */}
          <div
            className={classNames(
              'flex-grow items-center md:flex md:ml-auto',
              { 'flex': navbarOpen, 'hidden': !navbarOpen }
            )}
          >   
            <ul className="flex list-none flex-col md:flex-row md:space-x-6 ml-auto text-lg">
              <li>
                {/* Navigation link to Home page */}
                <Link
                  className="block py-2 hover:text-gray-200 transition duration-200"
                  to="/">
                  Home
                </Link>
              </li>
              <li>
                {/* Navigation link to Products page */}
                <Link
                  className="block py-2 hover:text-gray-200 transition duration-200"
                  to="/products">
                  Produktet
                </Link>
              </li>
              <li>
                {/* Navigation link to About page */}
                <Link
                  className="block py-2 hover:text-gray-200 transition duration-200"
                  to="/about">
                  Rreth nesh
                </Link>
              </li>
              {/*<li>*/}
                {/* Placeholder for future links such as Contact or Blog */}
               {/*<Link
                  className="block py-2 hover:text-gray-200 transition duration-200"
                  to="/contact">
                  Contact
                </Link>
              </li>*/}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};
